.contact-section {
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-color: white;
    border: 1px solid #cccccc80;
    border-radius: 10px;
    margin: auto;
    width: 85%;
    height: auto;
    overflow: hidden;

    &>img {
        width: 300px;
    }

    @media (max-width: 768px) {
        background-color: unset;
        border: none;
        width: 100%;
        flex-direction: column;
        height: auto;
    }

}

.contact-section:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.5);
}

.contact-content {
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 425px;
    padding: 15px;
    border: 1px solid #cccccc80;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 25px;

    @media (max-width: 768px) {
        background-color: white;
        border: 1px solid #cccccc80;
        width: 100%;
        margin: 0px;
    }
}

.contact-content:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.8);
}

.contact-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35vh;

    &-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 25vh;

        img {
            width: 5vh;
            height: auto;
            object-fit: cover;
        }
    }

    @media (max-width: 768px) {
        background-color: white;
        border: 1px solid #cccccc80;
        border-radius: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    .contact-img {
        @media (max-width: 768px) {
            display: none;
        }
    }
}