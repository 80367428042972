.App {
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  min-height: 100%;
  overscroll-behavior-block: contain;
}

.anchor {
  display: block;
  height: 115px;
  margin-top: -115px;
  visibility: hidden;
}

#anchor2 {
  display: block;
  height: 1200px;
  margin-top: -1200px;
  visibility: hidden;
}

#anchor3 {
  display: block;
  height: 700px;
  margin-top: -700px;
  visibility: hidden;
}

@media screen and (max-width: 780px) {
  .anchor {
    display: none;
  }
}