.project-page {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background-color: #f7f7ec;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.project-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.project-content {
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;

    margin-bottom: 30px;
}

.project-content:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.5);
}

.project-slide {
    flex: 1;

    width: 100vh;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 780px) {
        width: 95%;
        padding: 0px;
    }
}

.slide-track {
    height: 80vh;
}

img {
    max-width: 100%;
    height: auto;
}

.project-text {
    flex: 1;
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

p {
    font-size: 16px;
    line-height: 1.5;
}

.skill-item {
    display: inline-block;
    padding: 7px 15px;
    margin: 5px;
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(46, 99, 55, 1) 0%, rgba(53, 113, 63, 1) 47%, rgba(58, 122, 69, 1) 100%);
    color: white;
    font-size: 16px;
    text-align: center;
    transition: 300ms ease;
}

.skill-item:hover {
    background: linear-gradient(266deg, rgba(46, 99, 55, 1) 0%, rgba(53, 113, 63, 1) 47%, rgba(58, 122, 69, 1) 100%);
    transition: 300ms;
}

.project-link {
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    gap: 15px;
    text-decoration: none;
    color: black;
}

.project-link:hover {
    transform: scale(1.1);
}

.project-link-img {
    padding: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.projects-elements {
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 780px) {
        flex-direction: column;
        padding: 10px;
    }
}

.projects-elements:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.8);
}

.text {
    transition: 0.5s;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.text:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.8);
}