.skills-section {
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-color: white;

    border: 1px solid #cccccc80;
    border-radius: 10px;

    width: 85%;
    margin: auto;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.skills-section:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.5);
}

.canvas {
    background: rgba(158, 158, 158, 0.1);
}