.projects-section {
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    height: auto;

    margin: auto;

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.projects-section:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.5);
}

.project-description {
    transition: 0.5s;
    width: 80%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 15px;

    @media screen and (max-width: 480px) {
        text-align: start;
    }
}

.project-description:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.8);
}

.projects-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    flex-direction: column;

    background-color: white;
    border: 1px solid #cccccc80;
    border-radius: 10px;
    padding: 20px;

    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    width: 100%;
    margin: auto;

    @media screen and (max-width: 480px) {
        background-color: unset;
        border: none;
        box-shadow: none;
    }
}