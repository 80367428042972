.slick-dots li button:before {
    font-size: 10px;
}


.slick-track {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: stretch !important;
    max-height: 650px;

    @media screen and (max-width: 480px) {
        max-height: 280px;
    }
}

.slick-slide {
    max-height: 750px !important;
}