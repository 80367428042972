.projectSect {
    width: 75%;
    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px; // Ensure padding is consistent
    }
}

.card-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black;
    background-color: #fdfdfd;
    border: 1px solid rgba(158, 158, 158, 0.3);
    width: 240px;
    height: 445px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease;

    @media screen and (max-width: 480px) {
        border: 1px solid rgba(158, 158, 158);
        padding: 0px 0px 40px 0px !important;
        height: auto !important;
    }
}

.card-slide:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.card-img {
    width: 100%;
    height: 190px;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        height: auto;
    }
}

.card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-title {
    font-size: 20px;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-content {
    font-size: 16px;
    margin: 10px 0;
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: auto;
}

.skill-item {
    background-color: #f3f3f3;
    margin: 3px;
    padding: 5px;
    border-radius: 3px;
}

@media screen and (max-width: 480px) {
    .slick-track {
        max-height: none!important;
    }
}