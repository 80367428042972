.main-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-image: url('./geometric-background-with-copy-space.webp');
    background-size: cover;
    background-position: center;
    height: 100vh;
    overflow: hidden;
}

.typewrite {
    color: white;
    text-decoration: none;
    pointer-events: none;
    cursor: default;
}

.main-title {
    font-size: 100px;

    @media screen and (max-width: 480px) {
        font-size: 25px;
    }
}