.profile-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    width: 85%;
    height: auto;
    margin: auto;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
}

.profile-content {
    transition: 0.5s;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    background-color: #fff;
    border: 1px solid #cccccc80;

    border-radius: 10px;
    padding: 20px;
    width: 100%;

    @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
        background-color: unset !important;
        border: none;
        padding: 0px;
    }
}

.profile-content:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.5);
}

.profile-txt {
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    text-align: justify;
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    width: 45%;

    @media screen and (max-width: 768px) {
        width: 80%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 0px;
    }
}

.profile-txt:hover {
    box-shadow: 0 0 15px rgba(169, 185, 255, 0.8);
}

.profile-txt-content {
    padding: 10px;
}

.profile-txt-title {
    padding: 10px;
}

.profile-img {
    img {
        transition: 0.5s;
        border-radius: 50%;
        width: 450px;
        height: 450px;
        object-fit: cover;
        border: 3px solid #fff;
        box-shadow: 5px 6px 15px rgba(0, 0, 0, 0.4);

        @media screen and (max-width: 768px) {
            width: 350px;
            height: 350px;

        }

        @media screen and (max-width: 480px) {
            width: 250px;
            height: 250px;
        }
    }
}

.profile-img>img:hover {
    box-shadow: 5px 6px 15px rgba(169, 185, 255, 0.8);
}