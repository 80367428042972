form #Message {
    width: 250px;
    height: 150px;
    max-height: 200px;
    overflow-y: auto;
    text-align: start;

    @media screen and (max-width: 480px) {
        width: 85%;
        max-height: none;
    }
}

.form-container {
    flex: 2;

    @media (max-width: 768px) {
        flex: 1;
    }
}

form {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: normal;
    margin: 0px 0px 4px 0px;

    label {
        text-align: start;
        margin: 5px;
    }

    input,
    textarea {
        width: 85%;
        margin-bottom: 20px;
        border: solid 1px;
        height: auto;
    }

    input,
    textarea {
        border: none;
        border-bottom: 1px solid black;
        background-color: white;
        border-radius: 5px;
        height: 2.5em;
    }

    textarea {
        background-color: transparent;
        line-height: 2.5em;
        font-size: 1.2em;
    }

    .error {
        text-align: justify;
        padding: 5px;
        color: red;
    }


    @media (max-width: 768px) {
        width: 100%;
        align-items: center;
    }
}